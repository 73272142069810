import {graphql} from 'gatsby'
import React from 'react'

import Heading from '../components/heading'
import Image from '../components/image'
import Layout from '../components/layout'
import Link from '../components/link'
import List from '../components/list'
import ListItem from '../components/list-item'
import Paragraph from '../components/paragraph'
import SEO from '../components/seo'


export const frontmatter = {
  body: 'Home',
  slug: '/',
  title: 'Home',
}

export const query = graphql`
  query IndexPageQuery {
    ...SiteInfo
    imageMain: file(relativePath: { eq: "index.jpg" }) { ...ImageMain }
  }
`

export default ({data}) => {
  const {description, links, meta} = data.site.siteMetadata
  const {entity, genderization} = meta
  const imageMain = data.imageMain.childImageSharp.fixed

  const page = 'Home'
  const keywords = [page]
  const trail = [
    {
      name: page,
      slug: links.home,
    },
  ]
  const schema = {
    breadcrumb: trail,
    description,
    image: imageMain.src,
    keywords,
    name: page,
    slug: links.home,
  }

  return (
    <Layout>
      <SEO keywords={keywords} schema={schema}/>

      <Heading>{description}</Heading>

      <Paragraph>
        High-definition discovery of the best {genderization} scents for your
        unique aura.
      </Paragraph>

      <Image
        alt={description}
        credit="index.jpg"
        fixed={imageMain}
      />

      <List>
        <ListItem>
          <Link href={links.fragrances}>{entity.plural}</Link>
          <List basement={false}>
            <ListItem>
              <Link href={links.sale}>On Sale & Discount</Link>
            </ListItem>
            <ListItem>
              <Link href={links.new}>New Arrivals</Link>
            </ListItem>
          </List>
        </ListItem>
        <ListItem>
          <Link href={links.notes}>Families, Accords, and Notes</Link>
        </ListItem>
        <ListItem>
          <Link href={links.layers}>Fragrance Pyramid</Link>
        </ListItem>
        <ListItem>
          <Link href={links.brands}>Brands</Link>
        </ListItem>
        <ListItem>
          <Link href={links.countries}>Countries</Link>
        </ListItem>
        <ListItem>
          <Link href={links.noses}>Noses</Link>
        </ListItem>
        <ListItem>
          <Link href={links.seasons}>Seasons</Link>
        </ListItem>
        <ListItem>
          <Link href={links.clocks}>Times of Day</Link>
        </ListItem>
        <ListItem>
          <Link href={links.years}>Years</Link>
        </ListItem>
      </List>
    </Layout>
  )
}
